<template>
  <div class="dashboard-logistic-operator">
    <div class="username-content">
      <div
        v-if="name"
        class="d-inline-block name-user"
      >
        {{ `${$t('views.home.welcome') } ${ name }` }}
      </div>
      <rectoplus-button
        id="btn-redirect-offer-credit"
        class="btn-reuse-nfes"
        :text="$t('views.home.buttons.reuse_nfes')"
        @click="goToSendNFes()"
      />
    </div>
    <div
      v-if="hasPowerBi && hasOperationalCapacity"
      class="card-graph-representative"
    >
      <div class="content-iframe">
        <iframe
          width="100%"
          height="1024px"
          :src="getBusiness.power_bi"
          frameborder="0"
          allowFullScreen="true"
        />
      </div>
    </div>

    <div v-else>
      <base-card-create-offer
        @addOffer="addResume"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BaseCardCreateOffer from '@/components/cards/home/BaseCardCreateOffer.vue';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  name: 'DashboardLogisticOperator',

  components: {
    BaseCardCreateOffer,
    RectoplusButton,
  },

  props: {
    name: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      resume: [],
      offers: [],
    };
  },

  computed: {
    ...mapGetters([
      'getBusiness',
      'getNfesToOffer',
      'hasOperationalCapacity',
    ]),

    fullRegistration() {
      return !this.getBusiness.operational_capacity;
    },

    hasPowerBi() {
      return this.getBusiness.power_bi;
    },

    hasResume() {
      return this.resume.length > 0;
    },
  },

  watch: {
    getNfesToOffer(value) {
      if (value.length > 0) {
        this.$router.push({
          name: 'create-credit-offered',
          params: { offers: this.offers },
        });
      }
    },
  },

  methods: {
    ...mapActions([
      'setStepsNfe',
      'getMyNfes',
      'resetStateOfSuccess',
      'fetchNfesToOffer',
      'resetNfesToOffer',
    ]),

    addResume(offers) {
      this.resetNfesToOffer();
      const types = offers.map((offer) => offer.type);

      if (types.length <= 0) return;

      const query = {
        business_id: this.getBusiness.id,
        by_status: 'able_to_offer',
        by_material_type: types.join(','),
      };

      this.fetchNfesToOffer(query);

      this.offers = offers;
    },

    goToSendNFes() {
      this.setStepsNfe(1);
      this.resetStateOfSuccess();
      this.$router.push({ name: 'send-nfe' });
    },

    resetResume() {
      this.resume = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.dashboard-logistic-operator {

  .username-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .name-user {
      font-size: 1.8em;
      font-weight: bold;
    }

    .btn-reuse-nfes {
      width: 280px;
      height: 60px;
    }
  }

  .card-graph-representative {
    margin-top: 32px;
    background: $background_card_kind;
    border-radius: 10px;
    padding: 10px;
    padding-bottom: 50px;
    text-align: center;

    .content-iframe {
      height: 950px;
      overflow-y: hidden;
    }
  }
}
</style>
